import React from 'react'
import LoadableVisibility from 'react-loadable-visibility/react-loadable'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Hero from '../sections/hero'
import Loading from '../components/loading'

const Questions = LoadableVisibility({
  loader: () => import('../sections/questions'),
  loading: () => <Loading />,
  delay: 300,
})

const Stats = LoadableVisibility({
  loader: () => import('../sections/stats'),
  loading: () => <Loading />,
  delay: 300,
})

const VideoTiles = LoadableVisibility({
  loader: () => import('../sections/videoTiles'),
  loading: () => <Loading />,
  delay: 300,
})

const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
    <Questions />
    <Stats />
    <VideoTiles />
  </Layout>
)

export default IndexPage
