import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import CountUp from 'react-countup'
import circle from '../images/circle.svg'


const animationDuraction = 2


// var circle = document.getElementById('path')
// var radius = circle.getAttribute('r')
// var circleLength = 2 * Math.PI * radius
// document.getElementById('length-container').innerHTML = circleLength

const StyledCounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  margin: 0 10px;
  /* border: solid 2px rgb(${props => props.theme.lightPurple}); */
  /* border-radius: 50%; */
  background: url(${circle});
  background-size: 100%;
  background-repeat: no-repeat;
`

const StyledCounter = styled(CountUp)`
  color: rgb(${props => props.theme.lightPurple});
  font-size: 40px;
  font-family: ${props => props.theme.atramentSemiBoldItalic};
`

const Counter = props => (
  <StyledCounterWrapper>
    <StyledCounter end={props.numberToCount} duration={animationDuraction} />
  </StyledCounterWrapper>
)

Counter.propTypes = {
  numberToCount: PropTypes.number,
}

export default Counter
