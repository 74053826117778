import React from 'react'
import styled from 'styled-components'

import Counter from './counter'

const StyledHeadingWithNumber = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.theme.atramentSemiBoldItalic};
  font-size: 20px;
  margin-bottom: 30px;
`

const HeadingWithNumber = props => (
  <StyledHeadingWithNumber>
    Done in <Counter numberToCount={97} /> Seconds
  </StyledHeadingWithNumber>
)

export default HeadingWithNumber
