import React from 'react'
import styled from 'styled-components'

import DixonsLogo from '../images/dixons-carphone-logo--white.svg'
import ESFLogo from '../images/esf-logo--white.svg'
import HalfToneDB from '../images/half-tone--darkblue.svg'
import HalfToneDBMobile from '../images/half-tone--mobile--darkblue.svg'

import HeadingWithNumber from '../components/headingWithNumber'
import CTALink from '../components/ctaLink'

import HeaderMontage from '../video/header-montage.mp4'

const LogoWrapper = styled.div`
  display: flex;
  z-index: 1;
`

const Logo = styled.img`
  width: ${props => props.widthMobile};
  height: auto;
  margin-bottom: 15px;
  margin-right: 15px;
  @media screen and (min-width: ${props => props.theme.breakpointMd}) {
    width: ${props => props.width};
    margin-botton: 0;
  }
`

const StyledHero = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media screen and (min-width: ${props => props.theme.breakpointMd}) {
    flex-direction: row;
  }
`
const StyledHeroImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  min-height: 385px;
  background: #a051ff;
  padding: 15px 15px 60px;
  @media screen and (min-width: ${props => props.theme.breakpointMd}) {
    flex-shrink: 0;
    width: 60%;
    min-height: none;
    padding: 30px;
  }
  @media screen and (min-width: ${props => props.theme.breakpointLg}) {
    padding: 50px 50px 120px;
  }
`

const StyledMediaWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.4;
  filter: blur(2px);
  transform: scale(1.02);
  z-index: 0;
  img,
  video {
    object-fit: cover;
    font-family: 'object-fit: cover';
    width: 100%;
    height: 100%;
  }
`

const StyledHeroIntro = styled.div`
  position: relative;
  padding: 30px;
  background: rgb(${props => props.theme.navy});
  text-align: center;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.breakpointLg}) {
    padding: 120px 60px;
  }
  @media screen and (min-width: ${props => props.theme.breakpointXl}) {
    padding: 180px 60px;
  }
  p {
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
    span {
      display: block;
    }
    :last-of-type {
      margin-bottom: 50px;
    }
  }
  :before {
    position: absolute;
    bottom: 100%;
    left: 0;
    content: '';
    height: 40px;
    width: 100%;
    background: url(${HalfToneDBMobile});
    background-repeat: no-repeat;
    @media screen and (min-width: ${props => props.theme.breakpointMd}) {
      width: 60px;
      height: calc(100% + 10px);
      top: -10px;
      left: -60px;
      background: url(${HalfToneDB});
    }
    @media screen and (min-width: ${props => props.theme.breakpointLg}) {
      width: 80px;
      left: -75px;
    }
  }
`

// const StyledHeroInner = styled.div`
//   text-align: center;
//   align-self: flex-end;
//   z-index: 1;
//   margin-top: auto;
//   margin-left: auto;
//   margin-right: auto;
//   padding-left: 15px;
//   padding-right: 15px;
//   @media screen and (min-width: ${props => props.theme.breakpointMd}) {
//     padding-left: 30px;
//     padding-right: 30px;
//   }

//   p {
//     font-size: 14px;
//     margin-bottom: 0;
//     max-width: 660px;
//     @media screen and (min-width: ${props => props.theme.breakpointMd}) {
//       font-size: 16px;
//     }
//     @media screen and (min-width: ${props => props.theme.breakpointXl}) {
//       font-size: 20px;
//     }
//   }
//   h1 {
//     font-size: 35px;
//     line-height: 30px;
//     margin-bottom: 15px;
//     @media screen and (min-width: ${props => props.theme.breakpointMd}) {
//       font-size: 45px;
//       line-height: 45px;
//     }
//     @media screen and (min-width: ${props => props.theme.breakpointXl}) {
//       font-size: 60px;
//       line-height: 65px;
//     }
//   }
// `

const Hero = () => (
  <StyledHero>
    <StyledHeroImage>
      <LogoWrapper>
        <a
          href="https://www.dixonscarphone.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo
            src={DixonsLogo}
            width={'116px'}
            widthMobile={'103px'}
            alt="Dixons Carphone logo in white"
          />
        </a>
        <a
          href="https://www.electricalsafetyfirst.org.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo
            src={ESFLogo}
            width={'90px'}
            widthMobile={'76px'}
            alt="Electrical Safety First logo in white"
          />
        </a>
      </LogoWrapper>
      {/* <StyledHeroInner>
        <h1>Got 97 Seconds?</h1>
        <p>
          Then you have enough time to register your electrical products, but
          could you beat the clock doing this…
        </p>
      </StyledHeroInner> */}
      <StyledMediaWrapper>
        <video autoPlay={true} loop={true} muted={true} playsInline={true}>
          <source src={HeaderMontage} />
        </video>
      </StyledMediaWrapper>
    </StyledHeroImage>
    <StyledHeroIntro>
      <HeadingWithNumber />
      <h2>It takes around 97 seconds to register your gadget or appliance</h2>
      <p>
        <span>Giving you a lifetime of security.</span> If something goes wrong,
        the manufacturer can let you know and help you get it fixed.
      </p>
      <p>So what are you waiting for?</p>
      <CTALink linkTo={'/how-to-register/'} linkText={'How to register'} />
    </StyledHeroIntro>
  </StyledHero>
)

export default Hero
