import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const StyledCTALink = styled.a`
  display: inline-block;
  border: 2px solid
    rgb(${props => (props.white ? props.theme.white : props.theme.lightPurple)});
  font-family: ${props => props.theme.atramentRegular};
  font-size: 18px;
  color: rgb(
    ${props => (props.darkText ? props.theme.navy : props.theme.white)}
  );
  border-radius: 3px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.12px;
  padding: 10px 30px;
  /* min-width: 166px; */
  cursor: pointer;
  transition: transform 0.3s ease;

  :hover {
    transform: scale(1.05);
  }
`

const CTALink = props => {
  if (props.external) {
    return (
      <StyledCTALink
        href={props.linkTo}
        target="_blank"
        white={props.white}
        darkText={props.darkText}
        rel="noopener noreferrer"
      >
        {props.linkText}
      </StyledCTALink>
    )
  } else {
    return (
      <StyledCTALink
        as={Link}
        to={props.linkTo}
        white={props.white}
        darkText={props.darkText}
      >
        {props.linkText}
      </StyledCTALink>
    )
  }
}

CTALink.propTypes = {
  linkText: PropTypes.string,
  linkTo: PropTypes.string,
}

export default CTALink
